.login__container {
	width: 100%;
	height: 100%;
	background: url(../image/environmental-protection-326923_1920.jpg) no-repeat
		center 0px;
	background-size: cover;
}
.login__container.loading {
	background: url(../image/environmental-protection-326923_1920-s.jpg) no-repeat
	center 0px;
	background-size: cover;
}
.login {
	width: 100%;
	height: 100%;
	font-size: 1em;
	background: rgba(255,255,255,0.6);
	background-size: cover;
	background-attachment: fixed;
	color: #444444;
    font-family: "微軟正黑體", "Microsoft JhengHei", -apple-system,
        BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
        "Open Sans", "Helvetica Neue", sans-serif;
}

.login__center {
	box-sizing: border-box;
	margin: auto;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	padding: 40px;
    /* max-height: 500px; */
    overflow: hidden;
    overflow-y: auto;
	background: rgba(255, 255, 255, 0.8);
	box-sizing: border-box;
	box-shadow: 0 15px 25px rgba(0, 0, 0, 0.5);
}
@media screen and (min-width: 568px) {
	.login__center {
		box-sizing: border-box;
		margin: auto;
		position: absolute;
		top: 35%;
		left: 0;
		transform: translate(25%, -25%);
		width: 65%;
		height: auto;
		padding: 40px;
		background: rgba(255, 255, 255, 0.8);
		box-sizing: border-box;
		box-shadow: 0 15px 25px rgba(0, 0, 0, 0.5);
		border-radius: 10px;
	}
}
@media screen and (min-width: 768px) {
	.login__center {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 450px !important;
	}
}
.login__center .title {
	padding: 0;
	text-align: center;
	color:#00994e; /* #2C745B; */
    font-weight: 800;
	letter-spacing: 3px;
}
.login__center .subTitle {
	padding: 0.25rem 0 0.5rem 0;
	text-align: center;
	color: #707070;
    font-weight: 800;
	letter-spacing: 3px;
	border-top: 2px solid #c7c8c9;
}
.login__center h4 {
	margin: 0 0 30px;
}
.login__center .login__field {
	position: relative;
}
.login__center .login__field input {
	width: 100%;
	padding: 10px 0 0 0;
	font-size: 1.3rem;
	color: #212529;
	margin-bottom: 30px;
	border: none;
	border-bottom: 1px solid #fff;
	outline: none;
	background: transparent;
}
.login__center .login__field label {
	position: absolute;
	letter-spacing: 1px;
	padding: 10px 0;
	top: -23px;
	left: 0;
	color: #00994e;
	font-size: 0.85em;
	pointer-events: none;
}

.login__center button {
	border: none;
	outline: none;
	margin: 0.5rem 0;
	padding: 10px 0;
	letter-spacing: 2px;
	border-bottom: 4px solid #707070;
	transition: all 0.4s ease;
}
.login__center button:hover {
	margin: 1rem 0 0.5rem 0;
	border-bottom: 0px solid transparent;
}

.login__center .Tab_item_wrap {
	/* padding: 2em 0; */
	overflow: hidden;
	overflow-y: auto;
}

.login__center .Tab_item {
	margin: auto;
    width: 100%;
}

/* @media(max-width:769px){
    .login__center{
        width: 300px;
        margin: -150px 0 0 -150px;
    }
    .login__left{
        display: none;
    }
    .login__right{
        width: 100%;
    }
    
} */

/* .login:hover .imgAuthor { display: block; }
.imgAuthor {
	display: none;
	position: fixed;
	top:0;
	left:0;
	width: 100%;
	padding: 0 0.25em;
	background: rgba(0, 0, 0, 0.5);
	color: rgba(255, 255, 255, 0.8);
	text-shadow:  0 0 1px rgba(0, 0, 0, 0.5);
} */