.toggle-button {
    display: inline-block;
    position: relative;
    justify-content: space-around;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    height: 50px;
    width: 50px;
    top: 0;
    left: 0.5rem;
    &_frame {
        width: 45px;
        height: 45px;
        display: block;
        position: absolute;
        border:none;
        &.top {top: -8px;}
        &.center{top: 0;}
        &.bottom {top: 8px;}
    }
    &_line {
        display: block;
        position: absolute;
        width: 1.5rem;
        height: 2px;
        top: 30px; left: 0.5rem;
        background: #ffffff;
        border-radius: 0.5rem;
    }
    // enter
    &-cssTransition-enter {
        transition: all 0.3s ease-in;
        transform: rotateZ(0deg);
        .toggle-button_frame.top {
            top: -8px;
        }
        .toggle-button_frame.center {
            opacity: 1;
        }
        .toggle-button_frame.bottom {
            top: 8px;
        }
    }
    // enter-active
    &-cssTransition-enter-active {
        
        transition: all 0.4s ease-in-out;
        transform: rotateZ(360deg);
        .toggle-button_frame.top {
            left: 4px;
            top: 3px;
            transform: rotateZ(404deg);
            opacity: 0.8;
        }
        .toggle-button_frame.center {
            transform: rotateZ(360deg);
            opacity: 0.0;
        }
        .toggle-button_frame.bottom {
            left: 0px;
            top: 16px;
            transform: rotateZ(135deg);
            opacity: 0.8;
        }
    }
    // enter-done (X)
    &-cssTransition-enter-done {
        transform: rotateZ(360deg);
        transition: all 0.3s ease-in;
        .toggle-button_frame.top {
            left: 4px;
            top: 3px;
            transform: rotateZ(404deg);
        }
        .toggle-button_frame.center {
            opacity: 0;
        }
        .toggle-button_frame.bottom {
            left: 0px;
            top: 16px;
            transform: rotateZ(135deg);
        }
    }
    // exit
    &-cssTransition-exit {
        transition: all 0.3s ease-in-out;
        .toggle-button_frame.top {
            left: 4px;top: 3px;
            transform: rotateZ(404deg);
        }
        .toggle-button_frame.center {
            opacity: 0;
        }
        .toggle-button_frame.bottom {
            left: 0px;top: 16px;
            transform: rotateZ(135deg);
        }
    }
    // exit-active
    &-cssTransition-exit-active {
        transition: all 0.4s ease-in-out;
        transform: rotateZ(720deg);
        .toggle-button_frame.top {
            left: 0px;top: -4px;
            transform: rotateZ(0deg);
            opacity: 0.8;
        }
        .toggle-button_frame.center {
            opacity: 0.8;
        }
        .toggle-button_frame.bottom {
            left: 0px;top: 4px;
            transform: rotateZ(0deg);
            opacity: 0.8;
        }
    }
    // exit-done
    &-cssTransition-exit-done {
        // transition: all 0.3s ease-in;
        .toggle-button_frame.top {
            top: -8px;
            transform: rotateZ(0deg);
        }
        .toggle-button_frame.center {
            opacity: 1;
        }
        .toggle-button_frame.bottom {
            top: 8px;
            transform: rotateZ(0deg);
        }
    }
}
// @media screen and (min-width: 768px) {
//     .toggle-button { top: 0px;}
// }
// @media screen and (min-width: 1230px) {
//     .toggle-button { top: 10px;}
// }
