// loading
.loader{
	box-sizing: border-box;
	display: none;
	top:0; left:0;
	margin:auto;
	padding:0;
	position: fixed;
	width: 100%;
	height:100%;
	background: rgba(0, 0, 0, 0.4);
	text-align: center;
	z-index: 9999;
	&.active {
		display: block;
	}
	&--content {
		margin: auto;
		padding: 1em;
		position: relative;
		top: 40%;left: 0;
		width: 200px;
		background: #005b2f;
		color:#ffffff;
		font-size: 2em;
		border-radius: 1em;
		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
	}
}