.side-drawer {
    height: 100%;
    /* background: #00984e; */
    background: #edf3e9;
    box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 65px;
    left: 0;
    width: 70%;
    max-width: 400px;
    height: calc( 100% - 65px - 30px);
    z-index: 200;
    padding: 3% 0% 0% 0%;
    transform: translateX(-100%);
    transition: transform 0.3s ease-out;
    overflow: hidden;
    overflow-y: auto;
}

.side-drawer .side-drawer-placeholder-container {
    display: block;
    padding: 0% 15% 0% 9%;
    height: 3.11765em;
    font-weight: bold;
    border-bottom: 1px solid rgb(222, 217, 172);
}

.side-drawer .side-drawer-menuList {
    padding: 0% 15% 0% 9%;
}

.side-drawer ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 2;
}
.side-drawer li { position: relative;}
.side-drawer a {
    color: #444444;
    text-decoration: none;
    display: block;
    padding: 0.5rem 0;
    border-bottom: 1px solid rgb(222, 217, 172);
}

.side-drawer a:hover,
.side-drawer a:active {
    color: #00994e;
    border-bottom: 1px solid rgba(0, 153, 79, 0.7);
    font-weight: bold;
}
.side-drawer .dropdown-menu-app {
    display: none;
}
.side-drawer .dropdown-menu-app.active {
    display: block;
}

.side-drawer .dropdown-menu-app li{    
    padding-left: 2em;
}

.side-drawer .dropdown-menu-app a {
    color: #444444;
    text-decoration: none;
    display: block;
    padding: 0.5rem 0;
    border-bottom: 1px dashed rgb(222, 217, 172);
}

/* .menuList:hover .dropdown-menu-app {
    display: block;
} */
.side-drawer .dropdown-title{
    cursor: pointer;
    padding: 0.25rem 0;
}
.side-drawer .dropdown-title:hover{
    color: #00994e;
}
.side-drawer .dropdown-title.text,
.side-drawer .dropdown-menu-app .text,
.side-drawer .dropdown-menu-app .text a {
    color: #888888;
    cursor: default;
    border-bottom: 1px dashed rgb(222, 217, 172);
}
.side-drawer .dropdown-title.subGroup{
    padding: 0.75rem 0;
    border-bottom:1px solid rgb(222, 217, 172);
}


.side-drawer .dropdown-title.no-subGroup::after,.side-drawer .dropdown-title.text::after {
    display: none;
}
.side-drawer .dropdown-title::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: middle;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
}

.side-drawer .dropdown-menu-app li:hover a {
    position: relative;
    color: #00994e;
    /* border-bottom: 1px solid rgba(0, 153, 79, 0.7); */
    font-weight: bolder;
}
.side-drawer .dropdown-menu-app li.text:hover a {
    color: #888888;
    cursor: default;
}

.side-drawer .dropdown-menu-app li:hover a:before {
    content: '';
    position: absolute;
    top: 0;left: -25%;
    width: 100%; height: 100%;
    background: rgba(255, 255, 255, 0.7);
    border-radius: 0 100px 100px 0;
    box-shadow: 0px 2px 4px #dddddd;
    z-index: -1;
}



.side-drawer.open {
    transform: translateX(0);
    transition: transform 0.3s ease-out;
}

/* @media (min-width: 769px) {
    .side-drawer {
        display: none;
    }
} */

@media screen and (min-width: 576px) {
    .side-drawer {
        top: 80px;
        height: calc( 100% - 80px - 30px);
    }
}