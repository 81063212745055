%clearfix{
	box-sizing: border-box;
	margin: auto;
}
.footer {
    @extend %clearfix;
    display: block;
	position: fixed;
	bottom: 0;
    padding: 0.25em 0.5em;
    width: 100%;
	// background: #212529;
	background: #33b361;
	color: #ffffff;
	font-size: 14px;
	line-height: 1.8;
	// border-bottom: 5px solid #f2b12c;
	z-index: 999;
}