.toolbar {
    position: fixed;
    width: 100%;
    height: 65px;
    top: 0;left: 0;
    z-index: 500;
    @media screen and (min-width: 576px) {
        height: 80px;
    }
}

.toolbar__navigetion {
    display: block;
    position: relative;
    height: 100%;
    background:#00984e;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
}

.toolbar__logo {
    display: table-cell;
    height: 100%;
    font-family: "微軟正黑體", "Microsoft JhengHei", -apple-system,
        BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
        "Open Sans", "Helvetica Neue", sans-serif;
        a {
            display: inline-block;
            height: 100%;
            color: #ffffff;
            text-decoration: none;
        }
}

.yellow-bg ,.green-bg {
    display: inline-block;
    position: absolute;
    margin: 0;
}
.yellow-bg {
    right: 0;
    width: 50%;
    line-height: 65px;
    background: #fff200;
    .cathybk-logo {
        width: 150px;
        padding-left: 1em;
    }
    @media screen and (min-width: 576px) {
        line-height: 80px;
    }
    @media screen and (min-width: 768px) {
        width: calc( 100vw - 400px);
        .cathybk-logo {
            width: auto;
        }
    }
}
.green-bg {
    left: 0;
    width: 50%;
    height: 100%;
    position: relative;
    vertical-align: middle;
    background: #00984e;
    color: #ffffff;
    .ECO-logo{
        display: inline-block;
        width: 55%;
        margin: 0 0 0 1rem;
        // margin: 0.5rem 1em;
        font-size: 0.85rem;
    }
    @media screen and (min-width:640px) {
        // width: 400px;
        .ECO-logo { font-size: 1rem;}
    }
    @media screen and (min-width: 768px) {
        width: 400px;
        .ECO-logo { 
            margin: 0.5rem 1rem;
            font-size: 1.25rem;
        }
    }
}

.spacer {
    flex: 1;
}

.toolbar__navigetion-items{
    height: 100%;
    display: flex;
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: flex-end;
    }
    .menuList {    
        display: flex;
        position: relative;
        padding: 0 0.5rem;
        min-width: 115px;
        justify-content: center;
        font-size: 0.85rem;
        .dropdown-menu {
            color: #ffffff;
            // background: rgba(0, 132, 67, 0.8);
            background: #f0f8e9;
        }
        &:hover {
            .dropdown-menu {display: block;left: 0.5em;margin:0}
        }

    }
    .dropdown-title::after {
        display: inline-block;
        margin-left: .255em;
        vertical-align: middle;
        content: "";
        border-top: .3em solid;
        border-right: .3em solid transparent;
        border-bottom: 0;
        border-left: .3em solid transparent;
    }
    .menuList-logout{
        display: flex;
        min-width: 120px;
        justify-content: center;
        padding-right: 9px;
        line-height: 80px;
        .fa-sign-out-alt {
            line-height: 80px;
        }
    }
    
}





.toolbar__navigetion-items a {color: #444444;text-decoration: none;}

.toolbar__navigetion-items a:hover,
.toolbar__navigetion-items a :active {color: #00984e;}




.toolbar__toogle-button {
    display: inline-block;
    // width: 20%;
    &:focus{
        border:none;
    }
}

// @media(max-width:1024px) {
//     .toolbar__navigetion-items .menuList {
//         display: none;
//     }
//     .toolbar__logo a {
//         font-size: 1.2rem;
//     }
//     .toolbar__navigetion-items .menuList-logout {
//         min-width: 50px;
//     }
//     .spacer1 {
//         flex: 1;
//     }
// }

// @media(min-width:1025px) {
//     .toolbar__toogle-button {
//         display: none;
//     }
// }