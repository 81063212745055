$main-color: #00984e;
.Tab_title {
    display: inline-block;
    padding: 0.25rem;
    cursor: pointer;
    color: $main-color;
    &_wrap {
        border-bottom: 1px solid #dddddd;
    }
    &:hover {
        opacity: 0.7;
    }
    &.active {
        border-bottom: 2px solid $main-color;
    }
}
.Tab_item{
    display: none;
    width: 100%;
    &_wrap {
        margin: auto;
        padding: 2rem 0;
        &.scrollTable{
            margin: 0 !important;
        }
    }
    &.show {
        display: inline-block;
    }
}