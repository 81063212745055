h5 {
    font-weight: bold;
}

.msgbox {    
    top: 10%;
    margin-top: 1%;
    display: flex;
    align-items: center;
    width: 200px;
    right: 0;
    padding: 15px;
    position: fixed;
    color: #ffffff;
    border-radius: 5px;
    box-shadow: 0 0 1px rgba(0,0,0,0.25);
    border-left: 5px solid #ffc107;
    background: #00994e;
    /* transform: translateX(105%);
    transition: transform 0.5s ease-in; */
    z-index: 300;
}
.msgbox h5{    
    height: 1em;
}

.msgboxCss-enter {
    opacity: 0.01;
    transform: translateX(105%);
}

.msgboxCss-enter-active {
    opacity: 1;
    transform: translateX(0%);
    transition: all 0.5s ease-in;
}

.msgboxCss-exit {
    opacity: 1;
    transform: translateX(0%);
}

.msgboxCss-exit-active {
    opacity: 0.01;
    transform: translateX(105%);
    transition: all 0.5s ease-in;
}

/* .msgbox.open {
    transform: translateX(0%);
    transition: transform 0.5s ease-in;
}

.msgbox.hide {
    display: none;
} */

.closeButton {
    position: absolute;
    width: 30px;
    height: 30px;
    opacity: 0.5;
    border: 1.5px solid #8f130c;
    border-radius: 50%;
    right: 15px;
    text-align: center;
    color: #8f130c;
    font-weight: bold;
}