@charset "UTF-8";
$table-main-color: #444444;
$table-border-color: #444444;





/* layout */
* {margin: 0;padding: 0;}
html,body,#root,.app {
	box-sizing: border-box;
	margin: auto;
	padding: 0;
	height: 100%;
	width: 100%;
	max-height: 100%;
	max-width: 100%;
	// background: #e8f1ec;
	background: #efefef;
	color: #444444;
	font-family: "微軟正黑體", "Microsoft JhengHei", -apple-system,
		BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
		"Open Sans", "Helvetica Neue", sans-serif;
	font-size: 1em;
	letter-spacing: 0.4px;
	overflow: hidden;
}
.app { margin: auto auto 2em auto; height: auto;}
main {
	margin: 65px auto 5rem auto;
	padding: 0 0 4rem 0; // padding: 3em 0 4rem 0;
	max-width: 1920px;
	height: calc(100vh - 65px - 68.5px);
	overflow: auto;
	@media screen and (min-width:375px){
		height: calc(100vh - 65px - 45px);
	}
	@media screen and (min-width: 600px) {
		margin: 80px auto 5em auto;
		height: calc(100vh - 80px - 30px);
	}
}





// checkbox radio page-title bootstrap-custom-style other-plugin-style
@import './common.scss';
@import './input.scss';
// page
@import './page.scss';
//輸出EXCEL、PDF
// @import './exportTools.scss';
// box (方型)
@import './box.scss';
// box-o (圓圈)
@import './box-o.scss';
// table pagination
@import './table.scss';
// card
@import './card.scss';
// overview
@import './overview.scss';
// eco-5 setting page
@import './ECO5.scss';
// bankNav
@import './bankNav.scss';
// 電費單價設定
@import './priceOfElectricity.scss';
// chart
@import './chart.scss';

// 覆蓋套件樣式(bootstrap,BrainhubCarousel,material-ui select,react-datepicker,...)
@import './override.scss';


